import Confetti from "react-confetti";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { BackgroundResetPassword } from "../../../../app/components/design/MinimalistBackground";

import Success from "../../../../assets/success-process.svg";

const SuccessRegisterCustomer = () => {
    const [showConfetti, setShowConfetti] = useState(false);
    const { name = "" } = useParams();

    useEffect(() => {
        setShowConfetti(true);
        setTimeout(() => {
            setShowConfetti(false);
        }, 10000);
    }, []);

    return (
        <div className="container-fluid p-0 font-lato">
            <div className="stack">
                <BackgroundResetPassword />

                <div className="floating-container">
                    <div className="form-style">
                        <div className="row">
                            <div className="col-12 abs-center">
                                <img src={Success} alt="Imagen de storyset en Freepik" height={200} />
                            </div>
                            {
                                showConfetti && <Confetti
                                    width={window.innerWidth}
                                    height={window.innerHeight}
                                    className="animate__animated animate__fadeOut animate__delay-5s"
                                    style={{ animationDuration: "5s" }}
                                />
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <h4 className="text-center mb-3 fw-bold">¡Registro exitoso!</h4>
                                <p>🎉<b>Hola {name.split(" ")[0]}</b>🎉</p>

                                <p>Nos complace informarte que has registrado exitosamente tus datos en <b>Sortom</b>. Queremos darte la bienvenida a nuestra comunidad y agradecerte por unirte a nosotros. Ahora que has completado tus datos, continuaremos con el propósito con el que hemos pedido tus datos.</p>

                                <p>¡Gracias por elegir Sortom! Esperamos que disfrutes de tu experiencia con nosotros.</p>
                                <p>Saludos cordiales,
                                    <br />
                                    El equipo de <b>Sortom</b></p>
                            </div>

                        </div>

                        <div className="row mt-2">
                        <div className="col text-center">
                                <div onClick={() => window.location.href = "/auth/login"} className="btn btn-success text-white d-inline-block rounded-5 py-2 px-3 my-2">
                                    Ir al inicio
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessRegisterCustomer;