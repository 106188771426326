import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';

import { COLLECTION_PEOPLE, HIDE } from '../../../constant/constant';

export const ModalCreateActor = ({ show, setShow, firstRow, onAddMapping }) => {
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const { column, typePeople, label } = watch();
    const { listActorsSelected } = useSelector(state => state.documentsAutoSlice);

    const onSubmit = () => {
        const data = {
            collection: COLLECTION_PEOPLE,
            excel_column: parseInt(column),
            label_global_id: parseInt(label),
            type_peoples_id: parseInt(typePeople),
        }

        onAddMapping(data);
        reset();
        setShow(HIDE);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)} size='md'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header className='bg-green pe-4 d-flex flex-row align-items-center' closeButton>
                    <i className="fa-solid fa-person-circle-plus mt-2 me-2 animate__animated animate__backInRight fs-5"></i>
                    <h5 className="mt-2 me-2">Crear Persona</h5>
                </Modal.Header>
                <Modal.Body>
                    <label>Columna Excel<b><i className="text-red">*</i></b></label>
                    <select
                        className="form-select green-input mb-1"
                        {...register('column', { required: 'Campo de columna requerido' })}
                    >
                        <option value="">Seleccione...</option>
                        {
                            firstRow?.map((cell, index) => (<option key={index} value={index}>{cell}</option>))
                        }
                    </select>
                    {errors.column && <div className="fs-7 text-red text-end">{errors.column.message}</div>}

                    <label>Tipo de persona<b><i className="text-red">*</i></b></label>
                    <select
                        className="form-select green-input mb-1"
                        {...register('typePeople', { required: 'Tipo de persona es requerido' })}
                    >
                        <option value="">Seleccione...</option>
                        {
                            listActorsSelected?.map(actor => (<option key={actor.id} value={actor.type_peoples_id}>{actor.type_peoples?.name}</option>))
                        }
                    </select>

                    <label>Etiqueta<b><i className="text-red">*</i></b></label>
                    <select
                        className="form-select green-input mb-1"
                        {...register('label', { required: 'La etiqueta es requerida' })}
                    >
                        <option value="">Seleccione...</option>
                        {
                            listActorsSelected.find(actor => actor.type_peoples_id === parseInt(typePeople))?.
                                labels.map(label => (<option key={label.id} value={label.id}>{label.name}</option>))
                        }
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" title="Añadir mapeo" className="btn btn-success mt-3 d-block mx-auto">
                        <i className="fa-solid fa-plus text-white me-2" />
                        Añadir
                    </button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}