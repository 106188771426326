import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Header from './layouts/Header';

//Components Users
import { UsersView } from "../../modules/Usuarios/UsersView";
import { FormUser } from "../../modules/Usuarios/components/FormUser";
import { RolesView } from "../../modules/Roles/RolesView";

//Components Business
import Business from "../../modules/Empresas/components/business";
import FormBusiness from "../../modules/Empresas/components/createBusiness";


// //TODAS LAS RUTAS DEL MÓDULO DE TERCEROS - PARAMETRIZACIÓN  ------------------------------------------------------------------------
// //Componentes de terceros
import Typepeople from "../../modules/TercerosParam/componentes/typepeople";
// //Componentes de casos - grupos
import TypepeopleGroup from "../../modules/TercerosParam/componentes/grupos/group";
// //Componentes de casos-grupos-parameterizacion
import TypepeopleGroupParameterize from "../../modules/TercerosParam/componentes/grupos/parametrizacion/parameterize";
import FormParameterization from "../../modules/TercerosParam/componentes/grupos/parametrizacion/FormParameterization";

// //Componentes de terceros - categorias
import TypepeopleCategory from '../../modules/TercerosParam/componentes/categoria/category';
// //Componentes de terceros - categorías - grupos
import TypeopleCateogryGroup from '../../modules/TercerosParam/componentes/categoria/gruposCategoria/groupCategory';
// //Componentes de casos - categorías - grupos - parametrización 
import TypepeopleCategoryGroupParameterize from '../../modules/TercerosParam/componentes/categoria/gruposCategoria/parametrizacion/parameterize';
import FormCategoryParameterization from "../../modules/TercerosParam/componentes/categoria/gruposCategoria/parametrizacion/FormCategoryParameterization";

//------------------------------------------------------------------------------------------------------------------------------------------
//Componentes de documentos
import DocumentsAuto from "../../modules/DocumentsAuto/components/Document";
import ParametrizeDocumentsAuto from "../../modules/DocumentsAuto/components/parametrize/ParametrizeDocument";
import GenerateDocumentAuto from "../../modules/DocumentsAuto/components/generate/GenerateDocumentAuto";
import SignaturesView from "../../modules/DocumentsAuto/components/signatures/SignaturesView";

// Componentes de Terceros
import People from '../../modules/TercerosCreate/components/createPeople/people';
import ListPeople from '../../modules/TercerosCreate/components/ListPeople';
import Menu from "./layouts/Menu";

import { setTypepeople } from "../../reducers/typePeople/reducerTypepeople";

import LegalCase from "../../modules/LegalCaseParameterization/componentes/LegalCase";
import ClusterLegalCase from "../../modules/LegalCaseParameterization/componentes/cluster/ClusterLegalCase";
import ParameterizationLegalCase from "../../modules/LegalCaseParameterization/componentes/cluster/parameterization/ParameterizationLegalCase";
import FormParameterizationLegalCase from "../../modules/LegalCaseParameterization/componentes/cluster/parameterization/FormParameterizationLegalCase";
import CategoryLegalCase from "../../modules/LegalCaseParameterization/componentes/category/CategoryLegalCase";
import ClusterCategoryLegalCase from "../../modules/LegalCaseParameterization/componentes/category/cluster/ClusterCategoryLegalCase";
import ParameterizationCategoryLegalCase from "../../modules/LegalCaseParameterization/componentes/category/cluster/parameterization/ParameterizationCategoryLegalCase";

import ToastNotify from "./ToastNotify";

import ConfigurationView from "../../modules/Configuration/ConfigurationView";
import LoadingCustom from "./LoadingCustom";
import DashboardView from "../../modules/DashboardCompany/DashboardView";
import GeneralService from "../../services/GeneralService";
import { setCompany } from "../../reducers/business/reducerBusiness";
import { setListTypeDocument } from "../../reducers/user/reducerUsers";
import { useAlertConsumption } from "../hooks/useAlertConsumption";
import AlertConsumptionModal from "./AlertConsumptionModal";
import { setListLegalCase } from "../../reducers/legalCase/reducerLegalCase";
import FormParameterizationCategoryLegalCase from "../../modules/LegalCaseParameterization/componentes/category/cluster/parameterization/FormParameterizationCategoryLegalCase";
import CreateCase from "../../modules/CreateCase/components/CreateCase";
import FormCreateCase from "../../modules/CreateCase/components/form/FormCreateCase";

import Form from "../../modules/FormParameterization/componentes/Form";
import ClusterForm from "../../modules/FormParameterization/componentes/cluster/ClusterForm";
import ParameterizationForm from "../../modules/FormParameterization/componentes/cluster/parameterization/ParameterizationForm";
import FormParameterizationField from "../../modules/FormParameterization/componentes/cluster/parameterization/FormParameterizationField";
import { setListForm } from "../../reducers/forms/reducerFormSlice";
import ActorForm from "../../modules/FormParameterization/componentes/actor/ActorForm";
import ParameterizationActorForm from "../../modules/FormParameterization/componentes/actor/parameterization/ParameterizationActorForm";
import FormParameterizationActorField from "../../modules/FormParameterization/componentes/actor/parameterization/FormParameterizationActorField";
import TextFormParameterizationView from "../../modules/FormParameterization/componentes/TextFormParameterizationView";
import ProcessVirtualNotaryView from "../../modules/ProcessVirtualNotary/components/ProcessVirtualNotaryView";
import { BusinessChatView } from "../../modules/Chat/BusinessChatView";
import InProgressView from "../../modules/DocumentsAuto/components/noSigned/InProgressView";
import Mails from "../../modules/Email/components/Mails";

import { TypeProcessView, TypeMovementsView } from "../../modules/Expedients/parameterization";
import { ExpedientsView, ModifyExpedientView } from "../../modules/Expedients/generate";
import { setList as setListTypeProcess } from "../../reducers/expedient/reducerTypeProcess";
import { VisitsView } from "../../modules/DashboardCompany/VisitsView";
import { setBusinessProfiles, setProfile } from "../../reducers/auth/reducerLogin";
import { DefaultBusiness } from "./DefaultBusiness";
import { NetworkStatus } from "./NetworkStatus";
import { TimeOutErrorView } from "../../modules/Errors/TimeOutErrorView";
import { ImporterView } from "../../modules/Importers/ImporterView";
import { CreateImportView } from "../../modules/Importers/CreateImportView";

const Home = () => {
    const { company } = useSelector(state => state.businessSlice);
    const { show } = useSelector(state => state.toastSlice);
    const dispatch = useDispatch();
    useAlertConsumption();

    useEffect(() => {
        loadProfileInformation();
        getTypepeople();
        getTypeDocument();
        getLegalCase();
        loadForms();
        loadTypeProcess();
    }, []);

    const getTypepeople = async () => {
        const service = new GeneralService("typepeople");
        const { type_peoples = [] } = await service.getList(1000);
        dispatch(setTypepeople({ value: type_peoples }));
    }

    const getTypeDocument = async () => {
        const service = new GeneralService("type_document");
        const data = await service.getList(1000);
        const { type_document = [] } = data;
        dispatch(setListTypeDocument(type_document));
    }

    const getLegalCase = async () => {
        const service = new GeneralService("legal-case");
        const { legal_case = [] } = await service.getList(1000);
        dispatch(setListLegalCase({ value: legal_case }));
    }

    const loadForms = async () => {
        const service = new GeneralService("form");
        const { form = [] } = await service.getList(1000);
        dispatch(setListForm({ value: form }));
    }

    const loadTypeProcess = async () => {
        const service = new GeneralService("type-process");
        const { type_process = [] } = await service.getList(1000);
        dispatch(setListTypeProcess(type_process));
    }

    const loadProfileInformation = async () => {
        const service = new GeneralService("user/load-profile");
        const profile = await service.getList(1000);
        dispatch(setProfile(profile));

        const serviceBusiness = new GeneralService("business/" + profile.business_id);
        const response = await serviceBusiness.getRequest();
        dispatch(setCompany({ value: response }));

        const serviceProfiles = new GeneralService("user/profiles");
        const { profile: profiles = [] } = await serviceProfiles.getRequest();
        dispatch(setBusinessProfiles(profiles));
    }

    return (
        <>
            {/* Principal */}
            <div className="d-flex">
                <Menu />

                {show && <ToastNotify />}
                <NetworkStatus />
                <AlertConsumptionModal />
                <DefaultBusiness onChange={loadProfileInformation} />
                <LoadingCustom />

                <div className="container-fluid m-0 p-0">
                    {/* Header */}
                    <div className="row m-0 p-0">
                        <div className="col p-0">
                            <Header onChangeBusiness={loadProfileInformation} />
                        </div>
                    </div>
                    {/* Routers */}
                    <div className="row">
                        <div className="col">
                            <Router>
                                <Switch>
                                    <div className='content'>
                                        <main>
                                            <Route exact path="/" > <DashboardView /> </Route>
                                            {company.is_system_admin && <Route exact path="/visits" > <VisitsView /> </Route>}


                                            {/* Rutas de usuarios */}
                                            <Route exact path="/users/list"> <UsersView /> </Route>
                                            <Route exact path="/users/create"> <FormUser /> </Route>
                                            <Route exact path="/users/edit/:id"> <FormUser /> </Route>


                                            {/* Rutas de Acceso */}
                                            <Route exact path="/roles/list"> <RolesView /> </Route>


                                            {/* Rutas de empresa */}
                                            <Route exact path="/business/list"> <Business /> </Route>
                                            <Route exact path="/business/create"> <FormBusiness /> </Route>
                                            <Route exact path="/business/edit/:id"> <FormBusiness /> </Route>


                                            {/* RUTAS DE PARAMETRIZACIÓN DE TERCEROS */}
                                            {/* Terceros */}
                                            <Route exact path="/typepeople/list"> <Typepeople /> </Route>
                                            {/* Rutas de Terceros/Grupos */}

                                            <Route exact path="/typepeople/groups"> <TypepeopleGroup /> </Route>
                                            {/* Rutas de Terceros/Grupos/Parametrización */}
                                            <Route exact path="/typepeople-groups/parameterizations"> <TypepeopleGroupParameterize /> </Route>
                                            <Route exact path="/typepeople/create-parameterize"> <FormParameterization /> </Route>
                                            <Route exact path="/typepeople/edit-parameterize/:id"> <FormParameterization /> </Route>

                                            {/* Rutas de Terceros/Categorias */}
                                            <Route exact path="/typepeople/categories"> <TypepeopleCategory /> </Route>
                                            {/* Rutas de Terceros/Categorías/Grupos */}
                                            <Route exact path="/typepeople-categories/groups"> <TypeopleCateogryGroup /> </Route>
                                            {/* Rutas de Terceros/Categorías/Grupos/Parametrización */}
                                            <Route exact path="/typepeople-categories-groups/parameterizations"> <TypepeopleCategoryGroupParameterize /> </Route>
                                            <Route exact path="/typepeople-categories-groups/create-parameterize"> <FormCategoryParameterization /> </Route>
                                            <Route exact path="/typepeople-categories-groups/edit-parameterize/:id"> <FormCategoryParameterization /> </Route>

                                            {/* RUTAS DE PARAMETRIZACIÓN DE CASOS */}
                                            <Route exact path="/case-parameterization/list"> <LegalCase /> </Route>
                                            <Route exact path="/case-parameterization/cluster"> <ClusterLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/fields"> <ParameterizationLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/fields/create"> <FormParameterizationLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/fields/update/:id"> <FormParameterizationLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category"> <CategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/cluster"> <ClusterCategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/fields"> <ParameterizationCategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/fields/create"> <FormParameterizationCategoryLegalCase /> </Route>
                                            <Route exact path="/case-parameterization/category/fields/update/:id"> <FormParameterizationCategoryLegalCase /> </Route>

                                            <Route exact path="/case/list"> <CreateCase /> </Route>
                                            <Route exact path="/case/create"> <FormCreateCase /> </Route>
                                            <Route exact path="/case/update/:id"> <FormCreateCase /> </Route>

                                            <Route exact path="/documents-auto/list"> <DocumentsAuto /> </Route>
                                            <Route exact path="/documents-auto/parameterization/:id"> <ParametrizeDocumentsAuto /> </Route>
                                            <Route exact path="/documents-auto/generate/:id"> <GenerateDocumentAuto /> </Route>
                                            <Route exact path="/documents-auto/signatures"> <SignaturesView /> </Route>
                                            <Route exact path="/documents-auto/in-progress"> <InProgressView /> </Route>

                                            {/* RUTAS DE PARAMETRIZACIÓN DE FORMULARIOS */}
                                            <Route exact path="/form-parameterization/list"> <Form /> </Route>
                                            <Route exact path="/form-parameterization/cluster"> <ClusterForm /> </Route>
                                            <Route exact path="/form-parameterization/fields"> <ParameterizationForm /> </Route>
                                            <Route exact path="/form-parameterization/fields/create"> <FormParameterizationField /> </Route>
                                            <Route exact path="/form-parameterization/fields/update/:id"> <FormParameterizationField /> </Route>
                                            <Route exact path="/form-parameterization/actors"> <ActorForm /> </Route>
                                            <Route exact path="/form-parameterization/actors/fields"> <ParameterizationActorForm /> </Route>
                                            <Route exact path="/form-parameterization/actors/fields/create"> <FormParameterizationActorField /> </Route>
                                            <Route exact path="/form-parameterization/actors/fields/update/:id"> <FormParameterizationActorField /> </Route>
                                            <Route exact path="/form-parameterization/text/:id"> <TextFormParameterizationView /> </Route>

                                            <Route exact path="/process/virtual-notary/list"> <ProcessVirtualNotaryView /> </Route>

                                            {/* RUTAS DEL TERCERO - Crear*/}
                                            <Route exact path="/people/list"> <ListPeople /> </Route>
                                            <Route exact path="/people/create"> <People /></Route>
                                            <Route exact path="/people/edit/:id"> <People /></Route>

                                            <Route exact path="/configurations"> <ConfigurationView /></Route>

                                            <Route exact path="/chat"> <BusinessChatView /></Route>
                                            <Route exact path="/mails"> <Mails /></Route>

                                            <Route exact path="/type-process/list"> <TypeProcessView /></Route>
                                            <Route exact path="/type-movement/list"> <TypeMovementsView /></Route>
                                            <Route exact path="/expedient/list"> <ExpedientsView /></Route>
                                            <Route exact path="/expedient/create"> <ModifyExpedientView /></Route>
                                            <Route exact path="/expedient/edit/:id"> <ModifyExpedientView /></Route>

                                            <Route exact path="/errors/timeout"> <TimeOutErrorView /></Route>

                                            <Route exact path="/importers"> <ImporterView /></Route>
                                            <Route exact path="/importers/create"> <CreateImportView /></Route>
                                        </main>
                                    </div>
                                </Switch>
                            </Router>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Home;