import { useDispatch, useSelector } from "react-redux";

import GeneralService from "../../services/GeneralService";
import { useEffect } from "react";
import { reducerForm, setList } from "../../reducers/configuration/reducerZapsignConfiguration";
import CardSwitch from "../../app/components/CardSwitch";
import { toast } from "../../utils/toast";
import { ALERT_DANGER } from "../../constant/constant";

const TabZapsignConfiguration = () => {
    const { list } = useSelector(state => state.zapsignConfigurationSlice);
    const dispatch = useDispatch();
    const service = new GeneralService("/zapsign-configuration");

    const loadData = async () => {
        const res = await service.getList();
        dispatch(setList({ value: res.configurations }));
    }

    useEffect(() => {
        loadData();
    }, []);

    const setDataSwitch = async (index, item) => {
        if (item.active) {
            const res = await service.delete(item.id);
            if (!res.is_ok) {
                toast(dispatch, res.message, ALERT_DANGER);
                return;
            }
            dispatch(reducerForm({ id: 0, key: index, active: false }));
            return;
        }

        const res = await service.post({ type: item.type });
        if (!res.is_ok) {
            toast(dispatch, res.message, ALERT_DANGER);
            return;
        }
        dispatch(reducerForm({ id: res.configuration.id, key: index, active: true }));
    }

    return (
        <div className="animate__animated animate__fadeIn">
            <div className="row">
                <div className="col">
                    <h5 className="font-lato">
                        <i className="fa-solid fa-signature me-2"></i>
                        Firma electrónica
                    </h5>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <p>Establece las configuraciones por defecto de la firma electrónica</p>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <p>Formas de verificación</p>
                    <CardSwitch
                        icon="fa-solid fa-camera text-green"
                        name="require_selfie_photo"
                        title="Selfie"
                        checked={list[0].active}
                        onChange={() => setDataSwitch(0, list[0])}
                    />
                    <CardSwitch
                        icon="fa-solid fa-address-card text-green"
                        name="require_document_photo"
                        title="Documento de identidad"
                        checked={list[1].active}
                        onChange={() => setDataSwitch(1, list[1])}
                    />
                </div>
                <div className="col">
                    <p>Envío automático de firma a:</p>
                    {/* <CardSwitch
                        icon="fa-brands fa-square-whatsapp text-green"
                        name="automatic_whatsapp"
                        title="WhatsApp"
                        checked={list[2].active}
                        onChange={() => setDataSwitch(2, list[2])}
                    /> */}
                    <CardSwitch
                        icon="fa-solid fa-envelope text-green"
                        name="automatic_mailing"
                        title="Correo electrónico"
                        checked={list[3].active}
                        onChange={() => setDataSwitch(3, list[3])}
                    />
                </div>
            </div>

        </div>
    );
}

export default TabZapsignConfiguration;