import { useDispatch } from "react-redux";
import IconButton from "../../../../app/components/IconButton";

import { valueInputPeople } from "../../utils/functions";
import { setSelectedPerson } from "../../../../reducers/documents/reducerGenerateDocument";

const CardConfigActor = ({ person, active, canEdit, refForm }) => {
    const dispatch = useDispatch();

    const handleSelectActor = () => {
        if (!active) {
            if (refForm.current.checkValidity()) dispatch(setSelectedPerson({ value: person }));
            else refForm.current.reportValidity();
        }
    }

    return (
        <div className={`row p-2 card-actor ${active ? "bg-green text-purple" : ""}`}>
            <div className="col-10">
                {
                    canEdit ? valueInputPeople(person.people) : person.name
                }
            </div>
            <div className="col">
                <IconButton
                    icon="fa-solid fa-gears text-green"
                    title="Seleccionar Actor"
                    onClick={handleSelectActor}
                />
            </div>
        </div>
    )
}

export default CardConfigActor;