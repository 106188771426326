import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import GeneralService from '../../../services/GeneralService';
import { MessageError } from '../../../utils/message';
import IconButton from '../../../app/components/IconButton';
import { toast } from '../../../utils';
import { addRole, removeRole } from '../../../reducers/documents/reducerGenerateDocument';

export const ModalRoles = ({ show, setShow }) => {
    const { list } = useSelector(state => state.rolesSlice);
    const { dataDocument } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const handleAddRole = async (role) => {
        const service = new GeneralService("documents-auto/roles/add");

        const res = await service.post({ role_id: role.id, document_id: dataDocument.id });
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(addRole({ id: dataDocument.id, role }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleRemoveRole = async (role) => {
        const service = new GeneralService("documents-auto/roles/remove");
        const res = await service.post({ role_id: role.id, document_id: dataDocument.id });
        if (res.is_ok) {
            toast(dispatch, "Proceso exitoso");
            dispatch(removeRole({ id: dataDocument.id, role }));
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header className='bg-green pe-4 d-flex flex-row align-items-center' closeButton>
                <i className="fa-solid fa-key mt-2 me-2 animate__animated animate__backInRight fs-5"></i>
                <h5 className="mt-2 me-2">Roles - {dataDocument.name}</h5>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col ms-3">
                        {
                            list.map(role => <div className="row" key={role.id}>
                                <div className="col-10 font-lato">
                                    <i className="fa-solid fa-angles-right text-purple me-1"></i> {role.name}
                                </div>
                                <div className="col-2">
                                    {
                                        dataDocument?.roles?.some(itemRole => itemRole.id === role.id)
                                            ?
                                            <IconButton
                                                icon="fa-solid fa-circle-xmark text-red"
                                                title="Eliminar"
                                                onClick={() => handleRemoveRole(role)}
                                            />
                                            :
                                            <IconButton
                                                icon="fa-solid fa-circle-plus text-green"
                                                title="Añadir"
                                                onClick={() => handleAddRole(role)}
                                            />
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => setShow(false)} className="btn btn-success mx-auto rounded-3">
                    Listo
                </button>
            </Modal.Footer>
        </Modal >
    )
}