import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";

import GeneralService from "../../../../services/GeneralService";
import { MessageError } from "../../../../utils/message";
import { cleanData, setDataPeopleOnEdit } from "../../../../reducers/people/reducerPeople";
import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';
import { extractFieldsTypepeople } from "../../utils/extract";
import { hideLoading, showLoading } from "../../../../reducers/main/loadingReducer";
import { decodeToken } from "../../../../utils/decodeJWT";
import { validateDataForm } from '../../../FillOutForm/utils/extract';
import { ID_NOT_DEFINED } from '../../../../constant/constant';

export const useRegisterCustomer = () => {
    const { dataPeople } = useSelector(state => state.peopleSlice);
    const { selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const history = useHistory()
    const dispatch = useDispatch();
    let { uuid = "" } = useParams();
    const [templateData, setTemplateData] = useState({type_people_id: 0, name: ""});

    const loadTypePeople = async () => {
        if (templateData.type_people_id === ID_NOT_DEFINED) return;

        const service = new GeneralService("typepeople/" + templateData.type_people_id);
        const typePeople = await service.getRequest();
        dispatch(setSelectedTypepeople({ value: typePeople }));

        const data = {};
        const fieldsData = extractFieldsTypepeople(typePeople);

        fieldsData.map(param => {
            if (!param.required && param.alert_required && !param.is_category) {
                data[param.name] = {
                    value: "",
                    parameterization_id: param.id,
                    ...param
                }
            }
        });

        dispatch(setDataPeopleOnEdit({ value: data }));
    }

    const verifyUUID = async () => {
        const service = new GeneralService("people/check-uuid");
        const res = await service.post({ uuid });
        if (!res.is_ok) {
            history.replace("/customer/registration-status/error");
            return;
        }
        const { token } = res.customer_registration;
        setTemplateData(decodeToken(token));
    }

    const saveDataPeople = async (e) => {
        e.preventDefault();
        const service = new GeneralService("people/customer/create/" + uuid);

        const { listData, omit, isOk } = await validateDataForm(dataPeople);
        if (!omit || !isOk) {
            return false;
        }
        const data_peoples = listData;

        dispatch(showLoading());
        const res = await service.post({ type_peoples_id: selectedTypepeople.id, data_peoples });
        dispatch(hideLoading());
        if (res.is_ok) {
            dispatch(cleanData());
            history.replace("/customer/registration-status/successful/" + templateData.name);
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        verifyUUID();
    }, []);

    useEffect(() => {
        loadTypePeople();
    }, [templateData.type_people_id]);

    return {
        name: templateData.name,
        saveDataPeople,
    };
}
