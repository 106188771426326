import { createSlice } from '@reduxjs/toolkit';

export const reducerImporterSlice = createSlice({
    name: 'reducerImporterSlice',
    initialState: {
        selectedImporter: {
            file: '',
            collection: '',
        },
        list: [],
        listFilter: [],
        listMapping: [],
        listMappingActors: [],
        isLoading: false,
    },
    reducers: {
        setList: (state, { payload }) => {
            state.list = payload;
            state.listFilter = payload;
        },
        setListMapping: (state, { payload }) => {
            state.listMapping = payload;
        },
        setListMappingActors: (state, { payload }) => {
            state.listMappingActors = payload;
        },
        reducerFormSearch: (state, { payload }) => {
            if (payload === "") {
                state.listFilter = state.list;
                return;
            }
            const filtered = state.list.filter(dataImport => dataImport.file_name.toLowerCase().includes(payload.toLowerCase()))
            state.listFilter = filtered;
        },
        reducerForm: (state, action) => {
            state.selectedImporter[action.payload.key] = action.payload.value;
        },
    },
});

export const {
    setList,
    setListMapping,
    setListMappingActors,
    reducerFormSearch,
    reducerForm
} = reducerImporterSlice.actions;

export default reducerImporterSlice.reducer;