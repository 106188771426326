import { useEffect, useMemo, useState } from "react";
import IconButton from "../../../app/components/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { extractFieldsTypepeople } from "../../TercerosCreate/utils/extract";
import { setListMapping, setListMappingActors } from "../../../reducers/importer/reducerImporter";
import { setListActorsSelected, setListQuestions } from "../../../reducers/documents/reducerGenerateDocument";
import { COLLECTION_DOCUMENTS, COLLECTION_PEOPLE, SHOW } from "../../../constant/constant";
import GeneralService from "../../../services/GeneralService";
import { ModalCreateActor } from "./ModalCreateActor";

export const ConfigMappings = ({ firstRow, collection, typePeople, typeDocument }) => {
    const { list } = useSelector(state => state.typepeopleSlice);
    const { listQuestions } = useSelector(state => state.documentsAutoSlice);
    const { listMapping, listMappingActors } = useSelector(state => state.reducerImporterSlice);
    const { listActorsSelected } = useSelector(state => state.documentsAutoSlice);
    const [column, setColumn] = useState("");
    const [parameterization, setParameterization] = useState("");
    const [codeQuestionId, setCodeQuestionId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const parameterizations = useMemo(() => extractFieldsTypepeople(list.find(typeP => typeP.id === parseInt(typePeople)) ?? []), [typePeople]);

    const loadDataDocument = async () => {
        const service = new GeneralService("questions-documents");
        const res = await service.getList(1000, { documents_auto_id: typeDocument });
        const { questions_documents = [] } = res;
        dispatch(setListQuestions({ value: questions_documents }));

        const serviceActors = new GeneralService("actors-documents");
        const resActors = await serviceActors.getList(1000, { documents_auto_id: typeDocument });
        const { actors_documents = [] } = resActors;
        dispatch(setListActorsSelected({ value: actors_documents }));
    }

    const handleAddMapping = () => {
        if (collection === COLLECTION_PEOPLE) {
            if (!column || !parameterization) return;
            dispatch(setListMapping([...listMapping, {
                collection,
                excel_column: parseInt(column),
                parameterization_id: parseInt(parameterization),
            }]));
            setParameterization("");
        } else {
            if (!column || !codeQuestionId) return;
            dispatch(setListMapping([...listMapping, {
                collection,
                excel_column: parseInt(column),
                code_questions_id: parseInt(codeQuestionId),
            }]));
            setCodeQuestionId("");
        }

        setColumn("");
    }

    const handleAddPerson = (mappingPerson) => {
        dispatch(setListMappingActors([...listMappingActors, mappingPerson]));
    }

    const handleDeleteMapping = (index) => {
        let lista = [...listMapping];
        lista.splice(index, 1);
        dispatch(setListMapping(lista));
    }

    const handleDeleteMappingActors = (index) => {
        let lista = [...listMappingActors];
        lista.splice(index, 1);
        dispatch(setListMappingActors(lista));
    }

    useEffect(() => {
        if (collection === COLLECTION_DOCUMENTS) {
            loadDataDocument();
        }
    }, [collection, typeDocument]);

    return (
        <>
            <div className="bg-green rounded-4 p-4">
                <div className="row">
                    <div className="col-4">
                        <label>Columna Excel<b><i className="text-red">*</i></b></label>
                        <select
                            className="form-select mb-1"
                            value={column}
                            onChange={(e) => setColumn(e.target.value)}
                        >
                            <option value="">
                                Seleccione...
                            </option>
                            {
                                firstRow?.map((cell, index) => (
                                    <option key={index} value={index}>{cell}</option>
                                ))
                            }
                        </select>

                        {
                            collection === COLLECTION_PEOPLE
                                ?
                                <>
                                    <label>Campo parametrización<b><i className="text-red">*</i></b></label>
                                    <select
                                        className="form-select mb-1"
                                        value={parameterization}
                                        onChange={(e) => setParameterization(e.target.value)}
                                    >
                                        <option value="">
                                            Seleccione...
                                        </option>
                                        {
                                            parameterizations.map(param => <option
                                                value={param.id}
                                                key={param.id}
                                            >
                                                {param.name}
                                            </option>)
                                        }
                                    </select>
                                </>
                                :
                                <>
                                    <label>Pregunta<b><i className="text-red">*</i></b></label>
                                    <select
                                        className="form-select mb-1"
                                        value={codeQuestionId}
                                        onChange={(e) => setCodeQuestionId(e.target.value)}
                                    >
                                        <option value="">
                                            Seleccione...
                                        </option>
                                        {
                                            listQuestions.map(question => <option
                                                key={question.code_questions.id}
                                                value={question.code_questions.id}
                                            >
                                                {question.name}
                                            </option>)
                                        }
                                    </select>
                                </>
                        }

                        <button title="Añadir mapeo" className="btn btn-success mt-3 d-block mx-auto" type="button" onClick={handleAddMapping}>
                            <i className="fa-solid fa-plus text-white me-2" />
                            Añadir
                        </button>
                    </div>
                    <div className="col-8">

                        <div className="row">
                            <div className="col">
                                <h6 className="font-lato fw-bold">{collection === COLLECTION_PEOPLE ? "Configura los campos" : "Configura los diálogos"}</h6>
                                <div className="d-block rounded-3 clip-hide">
                                    <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn table-striped`}>
                                        <thead>
                                            <tr className='font-lato'>
                                                <th></th>
                                                <th>Columna Excel</th>
                                                <th>{collection === COLLECTION_PEOPLE ? "Campo parametrización" : "Pregunta"} </th>
                                                <th className="text-center">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listMapping.length === 0
                                                    ?
                                                    <tr>
                                                        <td className="text-center" colSpan="4">No has configurado ninguna columna</td>
                                                    </tr>
                                                    :
                                                    listMapping.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td></td>
                                                            <td>{firstRow?.[item.excel_column]}</td>
                                                            <td>
                                                                {
                                                                    collection === COLLECTION_PEOPLE
                                                                        ?
                                                                        parameterizations.find(p => p.id === parseInt(item.parameterization_id))?.name
                                                                        :
                                                                        listQuestions.find(q => q.code_questions?.id === parseInt(item.code_questions_id))?.name
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <IconButton
                                                                    icon="fa-solid fa-trash-can text-red"
                                                                    title="Eliminar mapeo"
                                                                    onClick={() => handleDeleteMapping(index)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {
                            collection === COLLECTION_DOCUMENTS
                            &&
                            <div className="row">
                                <div className="col">
                                    <h6 className="font-lato fw-bold">Establece las etiquetas</h6>
                                    <p>Recuerda configurar la columna que contiene el identificador de la persona</p>
                                    <div className="d-block">
                                        <span className="fw-bold">Agregar persona</span>
                                        <IconButton
                                            icon="fa-solid fa-plus-circle text-green"
                                            className="ms-3"
                                            onClick={() => setShowModal(SHOW)}
                                        />
                                    </div>

                                    <div className="d-block rounded-3 clip-hide">
                                        <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn table-striped`}>
                                            <thead>
                                                <tr className='font-lato'>
                                                    <th></th>
                                                    <th>Columna Excel</th>
                                                    <th>Etiqueta</th>
                                                    <th className="text-center">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listMappingActors.length === 0
                                                        ?
                                                        <tr>
                                                            <td className="text-center" colSpan="4">No has añadido ninguna persona</td>
                                                        </tr>
                                                        :
                                                        listMappingActors.map((item, index) => (
                                                            <tr key={item.id}>
                                                                <td></td>
                                                                <td>{firstRow?.[item.excel_column]}</td>
                                                                <td>
                                                                    <span className="btn-success text-white p-2 rounded-3 fs-8">
                                                                        {
                                                                            listActorsSelected.
                                                                                find(actor => actor.type_peoples_id === item.type_peoples_id)?.
                                                                                labels.find(label => label.id === item.label_global_id)?.name
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <IconButton
                                                                        icon="fa-solid fa-trash-can text-red"
                                                                        title="Eliminar mapeo"
                                                                        onClick={() => handleDeleteMappingActors(index)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <ModalCreateActor show={showModal} setShow={setShowModal} firstRow={firstRow} onAddMapping={handleAddPerson} />
        </>
    )
}
