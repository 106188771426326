import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";

import { VerifyEmail } from "./VerifyEmail";
import RecoverPasswordModal from "./RecoverPasswordModal";

import { LogVisitor } from "../../../app/components/LogVisitor";
import SolutionSortom from '../../../assets/solutionSortom.png';
import { startLoginWithEmailPassword } from "../../../reducers/auth/thunks";
import './../styles/style.css';

export const LoginView = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [showRecoverModal, setShowRecoverModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = async (data) => {
        dispatch(startLoginWithEmailPassword({...data, history}));
    }

    return (
        <>
            <form className="container-login" onSubmit={handleSubmit(onSubmit)} autoCorrect="on">
                <div className="forms-container">
                    <div className="signin-signup">
                        <div action="#" className="sign-in-form">
                            <VerifyEmail />
                            <h2 className="font-lato">Inicia sesión</h2>
                            <div className="input-field">
                                <i className="fas fa-user"></i>
                                <input
                                    type="email"
                                    {...register('email', {
                                        required: 'Correo requerido', minLength: 3, maxLength: 100,
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'El formato no es correcto'
                                        }
                                    })}
                                    placeholder="Correo"
                                />
                            </div>
                            {errors.email && <div className="fs-7 text-red text-end">{errors.email.message}</div>}
                            <div className="input-field">
                                <i className="fas fa-lock"></i>
                                <input
                                    type="password"
                                    {...register('password', { required: 'Contraseña requerida', minLength: 8 })}
                                    placeholder="Contraseña"
                                />
                            </div>
                            {errors.password && <div className="fs-7 text-red text-end">{errors.password.message}</div>}

                            <button type="submit" className="btn btn-success rounded-pill mt-2 px-4">
                                <i className="fa-solid fa-fingerprint me-2"></i>
                                Ingresar
                            </button>
                            <div className="pass pt-2" onClick={() => setShowRecoverModal(true)}>¿Olvidaste tu usuario o contraseña?</div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="panels-container">
                <div className="panel">
                    <h1 className='d-block font-lilita-one text-white text-center'>SORTOM </h1>
                    <img src={SolutionSortom} />
                </div>
            </div>

            <RecoverPasswordModal show={showRecoverModal} setShow={setShowRecoverModal} />
            <LogVisitor />
        </>
    )
}